<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Tablesub from '@components/subcategory/sub/tablesub'
// import Tablesub from '@components/subcategory/tablesub'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  components: {
    Layout,
    Tablesub,

    //  PageHeader
  },
  page: {
    title: '',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      data: [],
      category_name: '',
    }
  },
  mounted() {
    this.onLode()
    this.category_name = this.$route.params.child
  },
  methods: {
    async onLode() {
      await axios
        .get(`/api/subcategory/get/getsub/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data
        })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 v-if="category_name" class="mb-1 mt-0"
          >{{ category_name }} / {{ data.name }}</h4
        >
      </div>
      <div class="col-xl-6">
        <router-link
          :to="
            `/category/child/${category_name}/subinsub/${this.$route.params.id}/create`
          "
        >
          <button
            class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          >
            <feather type="file-plus"></feather>Create
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <Tablesub :category_code="this.category_name"> </Tablesub>
        </div>
      </div>
    </div>
  </Layout>
</template>
